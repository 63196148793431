<template>
<section id="wrapper">
  <div 
    v-if="$route.query.emailNotExists"
    class="login-register" 
  >
    <div class="login-box card" style="width:60%;">
      <div class="card-body" style="padding:20% 0">
        <center>
          <img src="images/logo-jasa-raharja.png" />
          <h4 class="mt-5"><b>User {{ $route.query.emailNotExists }} Belum Terdaftar</b></h4>
          <p>Silakan hubungi Administrator melalui servicedesk.</p>
        </center>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import GlobalVue from '@libs/Global'

export default {
  extends:GlobalVue,
  created(){
    if(!this.$route.query.emailNotExists){
      window.location = this.baseUrl
    }
  }
}
</script>

<style scoped>
@import "../assets/css/pages/login-register-lock.css";
</style>